import React from 'react'

const PaymentData = ({ db }) => {

    const dataOperation = db.data.dataOperation,
        paymentCode = dataOperation.PaymentCode,
        currencyCode = dataOperation.CurrencyCode,
        Amount = dataOperation.Amount,
        BussinesName = dataOperation.BussinesName

    return(
        <>
            <ul className="list-payment-data">
                <li>
                    <span>Pago de servicio</span>
                    <strong>{BussinesName}</strong>
                </li>
                <li>
                    <span>Código de pago</span>
                    <strong>{paymentCode}</strong>
                </li>
                <li>
                    <span>Total</span>
                    <strong>{currencyCode} {Amount}</strong>
                </li>
            </ul>
        </>
    )
}

export default PaymentData
