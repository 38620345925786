import React, {useState} from 'react'
import ListBanksOnline from '../organisms/ListBanksOnline'
import ListBanksCash from '../organisms/ListBanksCash'

const Tabs = ({ db }) => {
    const [tab, setTab] = useState('1')

    return(
        <>
            <div className="tabs">
                <ul className="tabs-head">
                    <li className={tab === '1' ?  `tabs-item ${tab} active` : `tabs-item ${tab}`} >
                        <button onClick={() => setTab('1')}>
                            <span>Transferencia bancaria</span>
                        </button>
                    </li>
                    <li className={tab === '1' ?  `tabs-item ${tab} ` : `tabs-item ${tab} active`} >
                        <button onClick={() => setTab('2')}>
                            <span>Pago en efectivo</span>
                        </button>
                    </li>
                </ul>
                <div className="tabs-container">
                    <h3>Selecciona tu banco para ver las instrucciones de pago</h3>
                    {tab === '1'? <ListBanksOnline db={db} typePayment={tab} /> : <ListBanksCash db={db} typePayment={tab} /> }
                </div>
            </div>
        </>
    )
}

export default Tabs
