import React from 'react'
import logo from '../../images/logo.png'
import bgmobile from '../../images/banner.jpg'

const Header = () => {
    return (
		<header>
            <div className="w">
                <img src={logo} className="logo" alt="Banner" />
                <img src={bgmobile} className="bg" alt="" />
            </div>
        </header>
    )
}

export default Header