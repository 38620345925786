import React, {useState} from 'react'
//import Icon from '../molecules/Icon'

const ListBanksCash = ({ db }) => {

    const listBanksCash = db.data.channel.Cash.listBanks

    const [slide, setSlide] = useState( listBanksCash && listBanksCash.length > 0 ? 0 : undefined )
    const [color, setColor] = useState (listBanksCash[0].Color)
    const [shortName, setShortName] = useState(listBanksCash[0].ShortName)
    const [urlBank, setUrlBank] = useState(listBanksCash[0].UrlBank)
    const [instructions, setInstructions] = useState(listBanksCash[0].Instructions)

    function goLeft(params){
        setSlide( params ? params - 1 : listBanksCash.length - 1)
        for( let i = 0; i < listBanksCash.length; i++ ){
            if( params === i && i !== -1){
                const newIndex = i - 1
                if(newIndex !== -1){
                    setColor(listBanksCash[newIndex].Color)
                    setShortName(listBanksCash[newIndex].ShortName)
                    setUrlBank(listBanksCash[newIndex].UrlBank)
                    setInstructions(listBanksCash[newIndex].Instructions)
                }else{
                    setColor(listBanksCash[listBanksCash.length - 1].Color)
                    setShortName(listBanksCash[listBanksCash.length - 1].ShortName)
                    setUrlBank(listBanksCash[listBanksCash.length - 1].UrlBank)
                    setInstructions(listBanksCash[listBanksCash.length - 1].Instructions)
                }
            }
        }
    }

    function goRight(params){
        setSlide((slide + 1) % listBanksCash.length)
        for( let i = 0; i < listBanksCash.length; i++ ){
            if( params === i && i !== listBanksCash.length ){
                const newIndex = i + 1 % listBanksCash.length
                if(newIndex !== listBanksCash.length){
                    setColor(listBanksCash[newIndex].Color)
                    setShortName(listBanksCash[newIndex].ShortName)
                    setUrlBank(listBanksCash[newIndex].UrlBank)
                    setInstructions(listBanksCash[newIndex].Instructions)
                }else{
                    setColor(listBanksCash[0].Color)
                    setShortName(listBanksCash[0].ShortName)
                    setUrlBank(listBanksCash[0].UrlBank)
                    setInstructions(listBanksCash[0].Instructions)
                }
            }
        }
    }

    const getBank = params => () =>  {
        setSlide(params)
        for( let i = 0; i < listBanksCash.length; i++ ){
            if( params === i){
                setColor(listBanksCash[i].Color)
                setShortName(listBanksCash[i].ShortName)
                setUrlBank(listBanksCash[i].UrlBank)
                setInstructions(listBanksCash[i].Instructions)
            }
        }
    }

    return(
        <>
            <div className="wrap-slider">
                <ul className={`slider s-`+slide} >
                    { listBanksCash.map( (item, index) => (
                        <li key={item.BankID}
                            className={index === slide ?  `slide-item ${slide} active` : `slide-item ${index}`}>
                            <button onClick={ getBank(index) }  >
                                <img className="img-bank" src={item.UrlLogo} alt={item.ShortName} />
                            </button>
                        </li>
                    ))}
                </ul>
                <button className="btn-arrow-left" data={slide} onClick={() => goLeft(slide)}>atrás</button>
                <button className="btn-arrow-right" data={slide} onClick={() => goRight(slide)}>adelante</button>
            </div>
            <p className="subtitle">Instrucciones de pago {shortName} </p>
            <h3 className="type-pay">Pago en Bancos</h3>
            
            <ul className="list-steps-1 paymentAgentTop paymentAgent">
                {instructions.map((item, index)=> (
                    (index === instructions.length - 1 )
                    ?
                    <li key={item.step} className={`item-${100/instructions.length} item-payment-agent`}>
                        <h3 className="type-pay">Pago en agentes</h3>
                        {/*{item.icon ?  <img src={item.icon} alt={item.step} /> :  <Icon strokeColor={color}  typeIcon = {5}/>*/}
                        {item.icon ?  <img src={item.icon} alt={item.step} /> :  '' }
                        <p dangerouslySetInnerHTML={ {__html: item.text} }></p>
                    </li>
                    :
                    <li key={item.step} className={`item-${100/instructions.length}`}>
                        {/*{item.icon ?  <img src={item.icon} alt={item.step} /> :  <Icon strokeColor={color}  typeIcon = {5}/>*/}
                        {item.icon ?  <img src={item.icon} alt={item.step} /> : '' }
                        <p>
                            <strong style={{ color: color }}>Paso {item.step}: </strong>
                            <span dangerouslySetInnerHTML={ {__html: item.text} }></span>
                        </p>
                    </li>
                ))}
            </ul>

            <div className="info">
                <a style={{ color: color }} href={urlBank} target="_blank" rel="noopener noreferrer" className="btn" >   Continuar comprando   </a>
                <p><b>Horario de atención de {shortName}</b> - de Lunes a viernes de 9 am. a 6 pm. - Sábado de 9 am. a 1 pm.</p>
            </div>
        </>
    )
}

export default ListBanksCash
