import React from 'react'

const IconPc = (props) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 446 446"  >
            <path style={{ fill: props.strokeColor }} d="M316.623 0H129.377C111.329 0 96.6333 14.6957 96.6333 32.7438V413.249C96.6333 431.304 111.329 446 129.377 446H316.615C334.671 446 349.367 431.304 349.367 413.256V32.7438C349.367 14.6957 334.671 0 316.623 0ZM111.5 59.4667H334.5V341.933H111.5V59.4667ZM129.377 14.8667H316.615C326.479 14.8667 334.5 22.8872 334.5 32.7438V44.6H111.5V32.7438C111.5 22.8872 119.521 14.8667 129.377 14.8667ZM316.623 431.133H129.377C119.521 431.133 111.5 423.113 111.5 413.256V356.8H334.5V413.256C334.5 423.113 326.479 431.133 316.623 431.133Z" fill="black"/>
            <path style={{ fill: props.strokeColor }} d="M223 364.233C206.602 364.233 193.267 377.569 193.267 393.967C193.267 410.365 206.602 423.7 223 423.7C239.398 423.7 252.733 410.365 252.733 393.967C252.733 377.569 239.398 364.233 223 364.233ZM223 408.833C214.801 408.833 208.133 402.166 208.133 393.967C208.133 385.768 214.801 379.1 223 379.1C231.199 379.1 237.867 385.768 237.867 393.967C237.867 402.166 231.199 408.833 223 408.833Z" fill="black"/>
            <path style={{ fill: props.strokeColor }} d="M193.267 37.1667H223C227.111 37.1667 230.433 33.844 230.433 29.7333C230.433 25.6227 227.111 22.3 223 22.3H193.267C189.156 22.3 185.833 25.6227 185.833 29.7333C185.833 33.844 189.156 37.1667 193.267 37.1667Z" fill="black"/>
            <path style={{ fill: props.strokeColor }} d="M245.3 37.1667H252.733C256.844 37.1667 260.167 33.844 260.167 29.7333C260.167 25.6227 256.844 22.3 252.733 22.3H245.3C241.189 22.3 237.867 25.6227 237.867 29.7333C237.867 33.844 241.189 37.1667 245.3 37.1667Z" fill="black"/>
        </svg>
    )
}

export default IconPc