import React, { useState } from 'react'
import styled from 'styled-components'
import QRCode from 'react-qr-code'
import { AiOutlineClockCircle } from 'react-icons/ai';
import dayjs from 'dayjs'
import Countdown from 'react-countdown';

import CardComponent from '../atoms/Card'
import CardMobileComponent from '../atoms/CardMobile'
import ModalBottom from '../atoms/ModalBottom'

const PixView = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState('Copiar')
  const [mobileCopyButtonText, setMobileCopyButtonText] = useState('Copiar código PIX')

  const handleCopyClick = () => {
    navigator.clipboard.writeText(data.data.channel.Pix.code)
    setCopyButtonText('Copiado')
    setTimeout(() => {
      setCopyButtonText('Copiar')
    }, 300);
  };

  const handleMobileCopyClick = () => {
    navigator.clipboard.writeText(data.data.channel.Pix.code)
    setMobileCopyButtonText('Copiado')
    setTimeout(() => {
      setMobileCopyButtonText('Copiar código PIX')
    }, 1000);
  };

  return (
    <Container>
      <CardsContainer>
        <CardContainer>
          <CardComponent
            title="Favorecido:"
            text={data.data.dataOperation.BussinesName}
          />
        </CardContainer>
        <CardContainer>
          <CardComponent
            title="Número do Pedido:"
            text={data.data.dataOperation.MerchantSalesID}
          />
        </CardContainer>
        <CardContainer>
          <CardComponent
            title="Valor Total:"
            text={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(data.data.dataOperation.Amount))}
          />
        </CardContainer>
        <CardContainer>
          <CardComponent
            title="Pedido válido até:"
            text={dayjs(data.data.dataOperation.TxExpiration).format('DD/MM/YYYY')}
            subtext={dayjs(data.data.dataOperation.TxExpiration).format('HH:mm:ss') + ' hrs'}
          />
        </CardContainer>
      </CardsContainer>
      <ModalBottom
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      >
        <CardMobileComponent
          title="Favorecido:"
          text={data.data.dataOperation.BussinesName}
        />
        <CardMobileComponent
          title="N. do pedido:"
          text={data.data.dataOperation.MerchantSalesID.slice(0, 10)}
        />
        <CardMobileComponent
          title="Valor Total:"
          text={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(data.data.dataOperation.Amount))}
        />
        <CardMobileComponent
          last={true}
          title="Válido até:"
          text={dayjs(data.data.dataOperation.TxExpiration).format('DD/MM/YYYY')}
          subtext={dayjs(data.data.dataOperation.TxExpiration).format('HH:mm:ss') + ' hrs'}
        />
      </ModalBottom>
      <InfoMobileContainer>
        <InfoMobileTextContainer>
          <InfoMobileText>
            Pague para {data.data.dataOperation.BussinesName} via<br />
            WePay4U o valor de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(data.data.dataOperation.Amount))}
          </InfoMobileText>
        </InfoMobileTextContainer>
      </InfoMobileContainer>
      <InfoContainer>
        <InfoTitle>Instruções para pagamento</InfoTitle>
        <StepContainer>
          <StepList>
            <li>Acesse o seu App bancário.</li>
            <li>Escolha pagar via PIX.</li>
            <li>Escaneie o código QR abaixo ou copie o código e pague seu pedido.</li>
            <li>Pronto! Seu pedido será processado e você receberá a confirmação em breve.</li>
          </StepList>
        </StepContainer>
        <PixInfoContainer>
          <QRContainer>
            <QRCode
              value={data.data.channel.Pix.code}
              size="168"
            />
          </QRContainer>
        </PixInfoContainer>
        <CodeContainer onClick={handleCopyClick}>
          <Code>{data.data.channel.Pix.code.substr(0, 51)}...</Code>
          <Copy>{copyButtonText}</Copy>
        </CodeContainer>
      </InfoContainer>
      <CopyButtonMobileContainer>
        <CopyButtonMobile onClick={handleMobileCopyClick}>{mobileCopyButtonText}</CopyButtonMobile>
      </CopyButtonMobileContainer>
      <InfoMobileButtonContainer>
        <InfoMobileButton onClick={() => setModalOpen(true)}>Confirme os dados do pedido</InfoMobileButton>
      </InfoMobileButtonContainer>
    </Container>
  )
}

export default PixView

const Container = styled.div`
  align-self: center;
  max-width: 846px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
`

const CardsContainer = styled.div`
  padding-bottom: 2rem;
  padding-right: 3rem;
  padding-top: 2.25rem;
  border-style: solid;
  border-width: 0;
  border-right-width: 1px;
  border-color: rgba(229, 231, 235, 1);
  max-width: 15rem;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`
const CardContainer = styled.div`
  margin-bottom: 1rem;
`

const InfoContainer = styled.div`
  margin-left: 3.125rem;
  margin-top: 1rem;
  grid-column: span 2 / span 2;
  @media only screen
  and (max-width : 700px) {
    grid-column-start: span 3;
  }
`
const InfoTitle = styled.span`
  color: ${({theme}) => theme.colors['wp-blue']};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
`

const StepContainer = styled.div`
  margin-left: 1.25rem;
  @media only screen
  and (max-width : 700px) {
    margin-right: 1.25rem;
  }
  margin-top: 0.25rem;
`
const StepList = styled.ol`
  color: ${({theme}) => theme.colors['wp-gray-1']};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  padding: 0;
  list-style-type: decimal;
`

const PixInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 2.25rem;
`
const QRContainer = styled.div`
  border-color: ${({theme}) => theme.colors['wp-blue']};
  border-style: solid;
  border-width: 2px;
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`
const WarningContainer = styled.div`
  color: ${({theme}) => theme.colors['wp-red-1']};
  font-weight: 600;
  text-align: center;
  padding: 0.75rem;
  background-color: ${({theme}) => theme.colors['wp-salmon']};
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`
const WarningText = styled.span`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0.625rem;
`
const CodeContainer = styled.div`
  padding: 0.5rem 1.25rem;
  background-color: ${({theme}) => theme.colors['wp-gray-0']};
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  max-width: 27.5rem;
  margin-top: 2.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media only screen
  and (max-width : 700px) {
    margin-right: 4rem;
  }
  &:active {
    border-color: ${({theme}) => theme.colors['wp-blue']};
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
  }
`
const Code = styled.span`
  grid-column: span 5 / span 5;
  @media only screen
  and (max-width : 700px) {
    grid-column: span 6 / span 6;
  }
  color: ${({theme}) => theme.colors['wp-gray-1']};
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.125rem;
  justify-content: center;
  flex-direction: column;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Copy = styled.div`
  color: ${({theme}) => theme.colors['wp-blue-1']};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  cursor: pointer;
  
  &:active {
    color: ${({theme}) => theme.colors['wp-blue']};
  }

  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`

const InfoMobileContainer = styled.div`
  display: none;
  @media only screen
  and (max-width : 700px) {
    display: flex;
  }
  width: 100%;
  justify-content: center;
  grid-column-start: span 3;
  margin-bottom: 1.5rem;
`
const InfoMobileTextContainer = styled.div`
  background-color: ${({ theme }) => theme.colors['wp-blue']};
  padding: 1.3rem 1.875rem;
  border-radius: 5px;
  text-align: center;
`
const InfoMobileText = styled.span`
  color: ${({theme}) => theme.colors['wp-white']};
  font-size: 1rem;
  font-weight: 500;
`
const CopyButtonMobileContainer = styled.div`
  display: none;
  @media only screen
  and (max-width : 700px) {
    display: flex;
  }
  width: 100%;
  justify-content: center;
  grid-column-start: span 3;
  margin-top: 2.25rem;
  margin-bottom: 1.5rem;
`
const CopyButtonMobile = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors['wp-blue']};
  padding: 0.625rem 1.1rem;
  color: ${({ theme }) => theme.colors['wp-white']};
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
`
const InfoMobileButtonContainer = styled.div`
  display: none;
  @media only screen
  and (max-width : 700px) {
    display: flex;
  }
  justify-content: center;
  grid-column-start: span 3;
  padding-top: 1.5rem;
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
  margin-left: 2.125rem;
  margin-right: 2.125rem;
  border-color: rgba(229, 231, 235, 1);
`
const InfoMobileButton = styled.div`
  padding: 0.625rem 2rem;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors['wp-blue']};
  color: ${({ theme }) => theme.colors['wp-blue']};
  cursor: pointer;
`