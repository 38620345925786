import React from 'react'
import Header from '../organisms/Header'
import PaymentData from '../molecules/PaymentData'
import IconSuccess from '../atoms/icons/IconSuccess'

const Success = ({ db }) => {

    return(
        <>
            <Header />
            <main className="wrap">
                <PaymentData db={db}/>
				<div className="payment-data">
                    <div className="confirm-data">
                        <IconSuccess color="#ff9100" width="40px" height="40px" />
                    </div>
					<h1 className="title">¡Pago realizado!</h1><br/><br/><br/><br/>
				</div>
            </main>
        </>
    )
}

export default Success