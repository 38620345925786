import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal';
import { IoMdClose } from 'react-icons/all'

Modal.setAppElement('#wepay4u');

const ModalBottomComponent = ({ modalOpen, setModalOpen, children }) => {
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(132, 132, 132, .4)',
        },
        content: {
          margin: 0,
          padding: 0,
          paddingLeft: 36,
          paddingRight: 36,
          paddingTop: 12,
          bottom: 0,
          left: 0,
          right: 0,
          top: '50%',
          borderBottom: 0,
          borderTopRightRadius: 15,
          borderTopLeftRadius: 15
        },
      }}
      contentLabel="Example Modal"
    >
      <ModalCloseContainer>
        <div onClick={() => setModalOpen(false)}>
          <IoMdClose size={20}/>
        </div>
      </ModalCloseContainer>
      {children}
    </Modal>
  )
}

export default ModalBottomComponent

const ModalCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: .25rem;
  color: ${({theme}) => theme.colors['wp-gris']};
`