import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import MenuComponent from '../organisms/Menu'

import PixView from '../organisms/PixStep'
import TransferView from '../organisms/TransferStep'
import BankBilletView from '../organisms/BankBilletStep'

import FooterComponent from '../organisms/FooterBRA'

const theme = {
  colors: {
    'wp-blue': '#4141EF',
    'wp-blue-1': '#0059D0',
    'wp-blue-2': '#0A276B',
    'wp-gris': '#484848',
    'wp-gray-0': '#EDEDED',
    'wp-gray-1': '#313131',
    'wp-gray-2': '#4F4F4F',
    'wp-gray-3': '#959595',
    'wp-gray-4': '#737373',
    'wp-gray-5': '#5B5B5B',
    'wp-gray-6': '#F1F1F1',
    'wp-gray-7': '#C0C0C0',
    'wp-gray-8': '#828282',
    'wp-gray-9': '#F9F9F9',
    'wp-gray-10': '#BFBFBF',
    'wp-gray-11': '#8D8D8D',
    'wp-gray-disabled': '#B1B1B1',
    'wp-green': '#219653',
    'wp-salmon': '#FFD4D4',
    'wp-red-1': '#BB0000',
    'wp-white': '#FFFFFF',
  }
}

const HomeBra = ({ db }) => {
  let channel
  if (
    db.data &&
    db.data.channel
  ) channel = db.data.channel

  console.log(db)

  const [step, setStep] = useState(channel.Pix ? 'pix' : channel.TransferOnline ? 'transfer' : 'bank-billet')

  let bankColors

  if (channel.TransferOnline) bankColors = db.data.channel.TransferOnline?.banks.reduce((acc, transfer) => ({
    ...acc,
    [`wp-color-${transfer.ShortName}`]: transfer.Color
  }), {})

  return (
    <div>
      <ThemeProvider theme={{
        ...theme,
        colors: {
          ...theme.colors,
          ...bankColors
        }
      }}>
        <MainContainer>
          <MenuContainer>
            <LogoContainer>
              <LogoImg src={db.data.dataOperation.Url_logo} alt="logo" />
            </LogoContainer>
            <div>
              <MenuComponent setStep={setStep} step={step} db={db} />
            </div>
          </MenuContainer>
          <StepContainer>
            {step === 'pix' && <PixView data={db} />}
            {step === 'transfer' && <TransferView setStep={setStep} db={db} />}
            {step === 'bank-billet' && <BankBilletView data={db} />}
          </StepContainer>
        </MainContainer>
        <FooterComponent />
      </ThemeProvider>
    </div>
  )
}

export default HomeBra

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  margin-left: 1rem;
  margin-right: 1rem;
`

const MenuContainer = styled.div`
  max-width: 846px;
  align-self: center;
  @media only screen
  and (min-width : 700px) {
    width: 846px;
  }
`

const LogoContainer = styled.div`
  margin-left: 0.75rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
`

const LogoImg = styled.img`
  width: 8rem;
  height: auto;
`

const StepContainer = styled.div`
  align-self: center;
  margin-top: 3.5rem;
  @media only screen
  and (max-width : 700px) {
    margin-top: 2rem;
  }
`
