import React from 'react'
import styled from 'styled-components'

const menuItems = [
  {key: 'pix', label: 'PIX', channelKey: 'Pix'},
  {key: 'transfer', label: 'Transferência', channelKey: 'TransferOnline'},
  {key: 'bank-billet', label: 'Boleto Bancário', channelKey: 'BankBillet'},
]

const Item = ({menuKey, label, active, onClick}) => {
  return (
    <ItemContainer key={menuKey} menuKey={menuKey}>
      <ItemTextContainer active={active}>
        <ItemText active={active}  onClick={onClick}>{label}</ItemText>
      </ItemTextContainer>
    </ItemContainer>
  )
}

const MenuComponent = ({ setStep, step, db }) => {
  return (
    <Container>
      <Menu>
        {menuItems
          .filter((item) => {
            return !!db.data.channel[item.channelKey]
          })
          .map(item => (
          <Item
            key={item.key}
            menuKey={item.key}
            label={item.label}
            active={step === item.key}
            onClick={() => setStep(item.key)}
          />
        ))}
      </Menu>
    </Container>
  )
}

export default MenuComponent

const Container = styled.div`
  height: 3.5rem;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: 0;
  border-color: rgba(229, 231, 235, 1);
  border-bottom-width: 1px;
  border-top-width: 1px;
`

const Menu = styled.div`
  grid-template-columns: repeat(6, minmax(0, 1fr));
  @media only screen
  and (max-width : 415px) {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }
  display: grid;
`

const ItemContainer = styled.div`
  grid-column-start: span 2;
  ${({ menuKey }) => {
    if (menuKey === 'pix') return `
      @media only screen 
      and (max-width : 415px) {
        grid-column-start: span 1;
      }
    `
  }}
  text-align: center;
  padding-top: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
`

const ItemTextContainer = styled.div`
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({theme, active}) => {
  if (active) return `
    border-bottom: 4px solid ${theme.colors['wp-blue']};
    padding-bottom: .55rem;`
}}
`

const ItemText = styled.span`
  cursor: pointer;
  font-size: 1.25rem;
  @media only screen
  and (max-width : 415px) {
    font-size: 1rem;
  }
  line-height: 1.75rem;
  color: ${({ theme, active }) => active ? theme.colors['wp-blue'] : theme.colors['wp-griss']}
`