import React from 'react';
import styled from "styled-components";
import toditoCash from '../../assets/images/todito-cash.png'

const HeaderMex = () => (
    <Header>
        <img src={toditoCash} alt="logo"/>
    </Header>
);

export default HeaderMex;

const Header = styled.div`
  max-width: 846px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  height: 90px;
  align-self: center;
  display: flex;
  align-items: center;
`
