import React from 'react'
import styled from 'styled-components'

const CardComponent = ({ title, text, subtext }) => {
  return (
    <Container>
      <div>
        <Title>{title}</Title>
      </div>
      <div>
          {text.length > 12 ? <Subtext>{text}</Subtext> : <Text>{text}</Text>}
      </div>
      <div>
        <Subtext>{subtext}</Subtext>
      </div>
    </Container>
  )
}

export default CardComponent

const Container = styled.div`
  --wp-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --wp-ring-offset-shadow: 0 0 #0000;
  --wp-ring-shadow: 0 0 #0000;
  box-shadow: var(--wp-ring-offset-shadow, 0 0 #0000), var(--wp-ring-shadow, 0 0 #0000), var(--wp-shadow);
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-style: solid;
  border-color: ${({theme}) => theme.colors['wp-blue']};
  border-width: 1px;
  border-radius: 0.25rem;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 12rem;
`

const Title = styled.span`
  color: ${({theme}) => theme.colors['wp-gray-2']};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
`
const Text = styled.span`
  color: ${({theme}) => theme.colors['wp-blue']};
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
`
const Subtext = styled.div`
  color: ${({theme}) => theme.colors['wp-blue']};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
