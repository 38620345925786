import Axios from 'axios'
import { GET_PAYMENT, GET_PAYMENT_STATUS, UPDATE_STATUS } from './actions'

const token = window.location.href.split('token=')[1]
let API_KEY
if (token) {
  API_KEY = token
} else {
  API_KEY = document.getElementById('wepay4u').getAttribute('data-apikey')
}

// const API_KEY = document.getElementById('wepay4u').getAttribute('data-apikey')
const API_URL = 'https://payment.wepay4u.com/api/v1/gateway/'
const API_URL2 = 'https://payment.wepay4u.com/api/v1/gateway/waittingState/'

export const getPayment = () => dispatch => {
  Axios.get(`${API_URL}${API_KEY}`).then(response => {
    return dispatch({
      type: GET_PAYMENT,
      // eslint-disable-next-line eqeqeq
      payment: process.env.REACT_APP_IS_TESTING == 1 ? {"status":true,"message":"TransactionPayment","data":{"dataOperation":{"TransactionID":2141,"PaymentCode":"3175813089098","LastStatus":12,"TxExpiration":"2021-10-15 07:32:21","CountryCode":"BRA","CurrencyCode":"BRL","Amount":"1.00","BussinesName":"MERCHANT DEMO","OkURL":"http:\/\/www.urlok.com","ErrorURL":"http:\/\/www.urlerror.com","PublicId":"Em_dev_b0e614ceqrz5jm","MerchantSalesID":"Test_BR_5","Url_logo":"https:\/\/dev-merchant-file.s3.amazonaws.com\/IMG_MERCHANTS\/Logodoradobet.png"}}} :  response.data
    })
  })
}

export const getPaymentStatus = () => dispatch => {
  Axios.get(`${API_URL2}${API_KEY}`).then(response => {
    return dispatch({
      type: GET_PAYMENT_STATUS,
      paymentStatus: response.data
    })
  })
}

export const updateStatus = newStatus => ({
  type: UPDATE_STATUS,
  data: newStatus
})
