import React, { useEffect } from 'react'
import store from '../src/redux/store'
import { getPayment } from './redux/actionsCreators'
import { connect } from 'react-redux'
import Expired from './components/pages/Expired'
import ExpiredBra from './components/pages/ExpiredBRA'
import Home from './components/pages/Home'
import HomeBra from './components/pages/HomeBRA'
import HomeMex from './components/pages/HomeMEX'
import SuccessBra from './components/pages/SuccessBRA'
import Success from './components/pages/Success'
import { getCountry } from './utils/helper'

const App = ({ payment }) => {
  useEffect(() => {
    store.dispatch(getPayment())
    const interval = setInterval(() => {
      store.dispatch(getPayment())
    }, 6000);
    return () => clearInterval(interval);
  }, [])


  if (payment) {
    const isStatusActive = payment.status
    const lastStatus = payment.data.dataOperation.LastStatus

    const country = getCountry(payment)

    if (isStatusActive === true) {
      if (lastStatus === 0) {
        return country === 'BRL' ? <ExpiredBra db={payment} /> : <Expired db={payment} />
      }
      if (lastStatus === 10) {
        if (payment.data.channel !== undefined) {
          switch (country) {
            case 'BRL':
              return <HomeBra db={payment} />
            case 'MXN':
              return <HomeMex db={payment} />
            default:
              return <Home db={payment} />
          }
          // return country === 'BRL' ? <HomeBra db={payment} /> : <Home db={payment} />
        } else {
          return country === 'BRL' ? <ExpiredBra db={payment} /> : <Expired db={payment} />
        }
      }
      if (lastStatus >= 12) {
        setInterval(() => {
          window.location = payment.data.dataOperation.OkURL
          return country === 'BRL' ? <SuccessBra db={payment} /> : <Success db={payment} />
        }, 2500);
        return country === 'BRL' ? <SuccessBra db={payment} /> : <Success db={payment} />
      }
    }
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  payment: state.paymentReducer.payment,
  status: state.statusReducer.data
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(App)
