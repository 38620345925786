import React from 'react'
import Header from '../organisms/Header'
import Footer from '../organisms/Footer'
import PaymentData from '../molecules/PaymentData'
import TimeExpireOrder from '../molecules/TimeExpireOrder'
import Tabs from '../templates/Tabs'

const Home = ({ db }) => {
	return (
        <>
			<Header />
			<main className="wrap">
				<div className="payment-data">
					<h1 className="title">¡Información de pago!</h1>
				</div>
				<PaymentData db={db}/>
				<TimeExpireOrder db={db} />
				<Tabs db={db} />
			</main>
			<Footer />
        </>
	)
}

export default Home
