import React from 'react'
import Header from '../organisms/Header'
import PaymentData from '../molecules/PaymentData'
import IconTime from '../atoms/icons/IconTime'

const Expired = ({ db }) => {
    return(
        <>
            <Header />
            <main className="wrap">
                <PaymentData db={db}/>
				<div className="payment-data">
                    <div className="time-data">
                        <IconTime color="#ff9100" width="24px" height="24px" />
                        <h1 className="title">Tu orden expiró</h1>
                    </div>
				</div>
                <a href={db.data.dataOperation.ErrorURL} className="btn">Generar nuevo código de pago</a>
            </main>
        </>
    )
}

export default Expired
