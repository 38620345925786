import React  from 'react'
import styled  from 'styled-components'

import FooterComponent from '../organisms/FooterBRA'
import { HiOutlineCheckCircle } from 'react-icons/hi'
import { IoArrowBack } from 'react-icons/io5'


const HomeBra = ({db}) => {
  return (
    <div>
      <MainContainer>
        <MenuContainer>
          <LogoContainer>
            <LogoImg src={db.data.dataOperation.Url_logo} alt="logo" />
          </LogoContainer>
        </MenuContainer>
        <StepContainer>
          <HiOutlineCheckCircle size={60} />
          <SuccessMessageContainer>
            Sua compra já foi processada
          </SuccessMessageContainer>
          <BackContainer onClick={() => window.location.replace(db.data.dataOperation.OkURL)}>
            <IoArrowBack style={{ marginRight: 14 }}/>
            Ir para o comercio
          </BackContainer>
        </StepContainer>
      </MainContainer>
      <FooterComponent />
    </div>
  )
}

export default HomeBra

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  margin-left: 1rem;
  margin-right: 1rem;
`

const MenuContainer = styled.div`
  max-width: 846px;
  @media only screen
  and (min-width : 700px) {
    align-self: center;
    width: 846px;
  }
`

const LogoContainer = styled.div`
  margin-left: 0.75rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  border-style: solid;
  border-width: 0;
  border-color: rgba(229, 231, 235, 1);
  border-bottom-width: 1px;
`

const LogoImg = styled.img`
  width: 8rem;
  height: auto;
`

const StepContainer = styled.div`
  color: #5EAB21;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 3.5rem;
  flex-direction: column;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`

const SuccessMessageContainer = styled.div`
  margin-top: 3.5rem;
  color: #FFF;
  padding: 1.25rem 7.625rem;
  background-color: #5EAB21;
  border-radius: 4px;
  @media only screen
  and (max-width : 700px) {
    padding: 1.25rem 2.625rem;
  }
`

const BackContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.875rem;
  color: #969696;
`