import React, {useState} from 'react';
import styled from "styled-components";
import HeaderMex from "../organisms/HeaderMEX";
import LoginPaymentMEX from "../organisms/LoginPaymentMEX";
import CreditCardPaymentMEX from "../organisms/CreditCardPaymentMEX";
import FooterMEX from "../organisms/FooterMEX";
import ModalMex from "../organisms/ModalMEX";
import axios from 'axios'

const HomeMex = ({db}) => {
    console.log(db.data.dataOperation)
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const urlToditoCash = 'https://payment.wepay4u.com/api/v1/toditocash/create'
    const [titleModal, setTitleModal] = useState('Error en el proceso');
    const [descriptionModal, setDescriptionModal] = useState('');
    const [textButton, setTextButton] = useState('Cerrar');

    const sendInfo = (form) => {
        const data = {...form, TransactionID: db.data.dataOperation.TransactionID}
        return axios.post(urlToditoCash, data, { auth: {username: '9876541235', password: ''}}).then(response => {
            setTitleModal('Proceso exitoso')
            setTextButton('Cerrar')
            setDescriptionModal(response.Message)
            setModalOpen(true)
        }).catch(err => {
            setTitleModal('Error en el proceso')
            setDescriptionModal(err.response.data.Message)
            setTextButton('Verificar')
            setModalOpen(true)
        })
    }
    return (
        <MainContainer>
            <HeaderMex/>
            <BodyContainer>
                <HeaderTabs>
                    <TitleTabs>
                        <ItemTitle onClick={() => setSelectedIndex(0)} isActive={selectedIndex === 0}>Cash</ItemTitle>
                        <ItemTitle onClick={() => setSelectedIndex(1)} isActive={selectedIndex === 1}>Tarjeta de crédito</ItemTitle>
                    </TitleTabs>
                </HeaderTabs>
                <ContainerTabs>
                    {
                        selectedIndex === 0 ? <LoginPaymentMEX sendInfo={sendInfo} setModalOpen={setModalOpen}/> : <CreditCardPaymentMEX setModalOpen={setModalOpen}/>
                    }
                </ContainerTabs>
            </BodyContainer>
            <ModalMex modalOpen={modalOpen} setModalOpen={setModalOpen}>
                <TitleModal>{titleModal}</TitleModal>
                <DescriptionModal>{descriptionModal}</DescriptionModal>
                <Button type="button" onClick={() => setModalOpen(false) }>{textButton}</Button>
            </ModalMex>
            <FooterMEX/>
        </MainContainer>
    )
};

export default HomeMex;

const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  position: relative;
`

const BodyContainer = styled.div`
  max-width: 846px;
  width: 100%;
  align-self: center;
`

const TitleTabs = styled.ul`
  display: flex;
  max-width: 360px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
`

const HeaderTabs = styled.div`
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  height: 50px;
`

const ContainerTabs = styled.div`
  display: flex;
  max-width: 360px;
  width: 100%;
  margin: 2rem auto;
`

const ItemTitle = styled.li`
  cursor: pointer;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;
  
  ${({isActive}) => {
      if(isActive) return`
          color: #00ABBE;
          font-weight: bold;
          border-bottom: 2px solid #00ABBE;
      `
  }}
  
  &:hover {
    color: #00ABBE;
    font-weight: bold;
    border-bottom: 2px solid #00ABBE;
  }
`

const TitleModal = styled.h2`
  text-align: center;
  font-family: "Roboto", sans-serif;
`

const DescriptionModal = styled.p`
  text-align: center;
  font-family: "Roboto", sans-serif;
`

const Button = styled.button`
  background-color: #EC2286 !important;
  color: #FFFFFF;
  padding: .8rem 2.5rem;
  margin: 1rem auto;
  border: none;
  cursor: pointer;
`
