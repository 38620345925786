import React from 'react'
import Logo from '../../assets/images/logo-footer.png'
import styled from 'styled-components'

const FooterComponent = () => {
    return (
        <Container>
            <ItemContainer>
                <ImageContainer>
                    <Image src={Logo} alt="wepay4u" />
                </ImageContainer>
            </ItemContainer>
        </Container>
    )
}

export default FooterComponent

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
`

const ItemContainer = styled.div`
  grid-column: span 6 / span 6;
  grid-column-start: 2;
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
  border-color: rgba(229, 231, 235, 1);
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 1.25rem;
  margin-top: 1.5rem;
`

const Image = styled.img`
  max-width: 9.875rem;
  height: auto;
`
