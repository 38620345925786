import React, {useState} from 'react';
import styled from "styled-components";
import {RiLoader4Fill} from "react-icons/all";
import { useForm } from 'react-hook-form'

const requiredRule = { value: true, message: 'Campo obligatorio' }
const maxLengthRule = (max) => {return { value: max, message: `Campo máximo de ${max} caracteres`}}
const minLengthRule = (min) => {return { value: min, message: `Campo mínimo de ${min} caracteres`}}

const ErrorMessage = ({ errors, field }) => {
    return (
        <ErrorMessageText
            message={errors?.[field]?.message}
        >
            {errors?.[field]?.message}
        </ErrorMessageText>
    )
}

const LoginPaymentMEX = ({sendInfo}) => {
    const {register, handleSubmit, formState: {errors}} = useForm()
    const [loading, setLoading] = useState(false);
    const submit = async (data) => {
        setLoading(true)
        await sendInfo(data)
        setLoading(false)
    }
    return (
        <LoginForm onSubmit={handleSubmit(submit)}>
            <GroupInput>
                <ItemLabel>Código identificador</ItemLabel>
                <ItemInput hasError={errors.CodigoIdentificador?.type} {...register("CodigoIdentificador", { required: requiredRule, maxLength: maxLengthRule(10), minLength: minLengthRule(10) })} />
                <ErrorMessage errors={errors} field="CodigoIdentificador" />
            </GroupInput>
            <GroupInput>
                <ItemLabel>Nip</ItemLabel>
                <ItemInput hasError={errors.Nip?.type} {...register("Nip", { required: requiredRule, minLength: minLengthRule(4), maxLength: maxLengthRule(4)})}/>
                <ErrorMessage errors={errors} field="Nip" />
            </GroupInput>
            <Submit type="submit" disabled={loading}>{loading ? <RiLoader4Fill  size={18} className="spin"/> : <span>Pagar</span>}</Submit>
        </LoginForm>
    )
};

export default LoginPaymentMEX;

const LoginForm = styled.form`
  width: 100%;
  padding: 0 1rem;
`

const GroupInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
`

const ItemLabel = styled.label`
  color: #5B5B5B;
  margin-bottom: .5rem;
`

const ItemInput = styled.input`
  display: block;
  width: 100%;
  padding: .7rem 1rem;
  background: #FDFDFD;
  border: 1px solid #CDCDCD;
  border-radius: 3px;

  ${({hasError}) => hasError ? 'border: 1px solid red;' : ''}
`

const Submit = styled.button`
  background-color: #EC2286 !important;
  color: #FFFFFF;
  width: 100%;
  padding: .8rem;
  margin-top: 3rem;
  border-radius: 3px;
`

const ErrorMessageText = styled.span`
  ${({message}) => !message ? 'display: none;' : ''}
  color: rgba(239, 68, 68, .8);
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.25rem;
`
