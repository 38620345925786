import React from 'react'
import IconTime from '../atoms/icons/IconTime'

const TimeExpireOrder = ({ db }) => {

    return(
        <div className="time-data">
            <IconTime color="#ff9100" width="24px" height="24px" />
            <p><b>Tu orden expirará el</b><span>{db.data.dataOperation.TxExpiration}</span></p>
        </div>
    )
}
export default TimeExpireOrder