import React from 'react'

const IconTime = (props) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill={props.color} width={props.width} height={props.height} >
            <g>
                <path d="M431.362,80.638A248,248,0,1,0,80.638,431.362,248,248,0,1,0,431.362,80.638ZM420.049,420.049a232,232,0,0,1-328.1-328.1,232,232,0,0,1,328.1,328.1Z"/>
                <path d="M256,48C141.309,48,48,141.309,48,256s93.309,208,208,208,208-93.309,208-208S370.691,48,256,48ZM397.233,385.92l-11.126-11.126a8,8,0,0,0-11.313,11.313l11.126,11.126A191.264,191.264,0,0,1,264,447.821V432a8,8,0,0,0-16,0v15.821a191.264,191.264,0,0,1-121.92-50.588l11.126-11.126a8,8,0,0,0-11.313-11.313L114.767,385.92A191.264,191.264,0,0,1,64.179,264H80a8,8,0,0,0,0-16H64.179a191.264,191.264,0,0,1,50.588-121.92l11.126,11.126a8,8,0,0,0,11.313-11.313L126.08,114.767A191.264,191.264,0,0,1,248,64.179V80a8,8,0,0,0,16,0V64.179a191.264,191.264,0,0,1,121.92,50.588l-11.126,11.126a8,8,0,1,0,11.313,11.313l11.126-11.126A191.264,191.264,0,0,1,447.821,248H432a8,8,0,0,0,0,16h15.821A191.264,191.264,0,0,1,397.233,385.92Z"/>
                <path d="M336,248H286.987A32.115,32.115,0,0,0,264,225.013V128a8,8,0,0,0-16,0v97.013A32,32,0,1,0,286.987,264H336a8,8,0,0,0,0-16Zm-80,24a16,16,0,1,1,16-16A16.019,16.019,0,0,1,256,272Z"/>
                <path d="M112,248h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z"/>
            </g>
        </svg>
    )
}

export default IconTime