import React  from 'react'
import styled  from 'styled-components'

import FooterComponent from '../organisms/FooterBRA'
import { RiErrorWarningLine } from 'react-icons/ri'
import { IoArrowBack } from 'react-icons/io5'


const ExpiredBra = ({db}) => {
  return (
    <div>
      <MainContainer>
        <MenuContainer>
          <LogoContainer>
            <LogoImg src={db.data.dataOperation.Url_logo} alt="logo" />
          </LogoContainer>
        </MenuContainer>
        <StepContainer>
          <RiErrorWarningLine size={55} />
          <MessageContainer>
            Que pena : ( sua transação já expirou e você não poderá utilizar esse token para realizar seu pagamento, mas não se preocupe...
          </MessageContainer>
          <MessageInfoContainer>
            Em caso você já o tenha feito e ainda não recebeu a confirmação por email ou SMS, por favor nos escreva
            a <a href="mailto:suporte@wepay4.com">suporte@wepay4.com</a> para que nosso time possa te auxiliar a revisar o motivo.<br /><br /><br />
            Caso contrário, você poderá gerar um novo pedido no site do comércio, um novo token será gerado, e aí é só
            seguir o passo a passo para confirmar sua compra
          </MessageInfoContainer>
          <BackContainer onClick={() => window.location.replace(db.data.dataOperation.ErrorURL)}>
            <IoArrowBack style={{ marginRight: 14 }}/>
            Voltar
          </BackContainer>
        </StepContainer>
      </MainContainer>
      <FooterComponent />
    </div>
  )
}

export default ExpiredBra

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  margin-left: 1rem;
  margin-right: 1rem;
`

const MenuContainer = styled.div`
  max-width: 846px;
  @media only screen
  and (min-width : 700px) {
    align-self: center;
    width: 846px;
  }
`

const LogoContainer = styled.div`
  margin-left: 0.75rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  border-style: solid;
  border-width: 0;
  border-color: rgba(229, 231, 235, 1);
  border-bottom-width: 1px;
`

const LogoImg = styled.img`
  width: 8rem;
  height: auto;
`

const StepContainer = styled.div`
  color: #EE5626;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  @media only screen
  and (min-width : 700px) {
    margin-top: 3.5rem;
  }
  flex-direction: column;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`

const MessageContainer = styled.div`
  margin-top: 3.5rem;
  color: #FFF;
  padding: 1.25rem 2.25rem;
  background-color: #EE5626;
  border-radius: 4px;
  max-width: 820px;
`

const BackContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen
  and (min-width : 700px) {
    margin-top: 1.875rem;
  }
  color: #969696;
`

const MessageInfoContainer = styled.span`
  padding: 1.625rem 1.25rem 1rem;
  @media only screen
  and (min-width: 700px) {
    padding-bottom: 7.125rem;
  }
  max-width: 774px;
  font-size: 1rem;
  color: #919191;

  a {
    color: #516CF4;
    text-decoration: none;
  }
`