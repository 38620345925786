import { GET_PAYMENT, GET_PAYMENT_STATUS, UPDATE_STATUS } from "./actions"

export const paymentReducer = (state = {}, action) =>{
    if( action.type === GET_PAYMENT){
        return {
            ...state,
            payment: action.payment
        }
    }
    return state
}

export const paymentStatusReducer = (state = {}, action) => {
    if( action.type === GET_PAYMENT_STATUS){
        return{
            ...state,
            paymentStatus: action.paymentStatus
        }
    }
    return state
}


const initialStore = {
    status: 0
}

export const statusReducer = (state = initialStore, action) => {

    if (action.type === UPDATE_STATUS) {
        //console.log(action)
        //console.log('%c Reducer status initial: ', 'color: red; font-weight: bold', state.status)
        //console.log('%c Reducer action data: ', 'color: red; font-weight: bold', action.data)

        //state.status.find( a => a === action.number )
            return {
                ...state,
                //status: action.data
                status: state.status+action.data
            }


    }
    //console.log('%c Reducer Estado: ', 'color: red; font-weight: bold', state)
    return state
}
