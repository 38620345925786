import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const BankCardComponent = ({ bank = 'bradesco', active, changeFunc, db }) => {
  const [images, setImages] = useState(null)

  useEffect(() => {
    if (db && db.data) setImages(
      db.data.channel.TransferOnline.banks.reduce((acc, transfer) => ({
        ...acc,
        [transfer.ShortName]: {
          active: transfer.UrlLogo,
          disabled: transfer.UrlLogoDisabled,
          width: '150'
        }
      }), {})
    )
  }, [db]);


  return images ? (
    <Container bank={bank} active={active} onClick={() => changeFunc(bank)}>
        {
            images[bank].active !== 'n/a' && images[bank].active
                ? <Image src={images[bank].active} alt={`Banco ${bank}`} bank={bank} />
                : <Label>{bank}</Label>
        }
    </Container>
  ) : <></>
}

export default BankCardComponent

const Container = styled.div`
  display: flex;
  height: 2.6rem;
  width: 7.1rem;
  cursor: ${({active}) => active ? 'default' : 'pointer'};
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  background-color: ${({theme, bank}) => theme.colors[`wp-color-${bank}`]};
  text-align: center;
  overflow: hidden;
  filter: ${({active}) => active ? 'none' :  'opacity(.9)'};
  border: 3px solid ${({theme}) => theme.colors['wp-white']};
  outline: 3px solid ${({theme, active}) => active ? theme.colors['wp-blue'] : theme.colors['wp-white']};
`
const Image = styled.img`
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: right;
`

const Label = styled.span`
  font-weight: bold;
  color: white;
`
