import React from 'react'

const IconPrint = (props) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <path style={{ fill: props.strokeColor }} d="M472.178,133.907h-54.303V35.132c0-9.425-7.641-17.067-17.067-17.067H111.192c-9.425,0-17.067,7.641-17.067,17.067v98.775
                H39.822C17.864,133.907,0,151.772,0,173.73v171.702c0,21.958,17.864,39.822,39.822,39.822h54.306v91.614
                c0,9.425,7.641,17.067,17.067,17.067h289.61c9.425,0,17.067-7.641,17.067-17.067v-91.614h54.306
                c21.958,0,39.822-17.864,39.822-39.822V173.73C512,151.773,494.136,133.907,472.178,133.907z M128.258,52.199h255.483v81.708
                H128.258V52.199z M383.738,459.801H128.262c0-3.335,0-135.503,0-139.628h255.477C383.738,324.402,383.738,456.594,383.738,459.801
                z M477.867,345.433c0,3.137-2.552,5.689-5.689,5.689h-54.306v-48.014c0-9.425-7.641-17.067-17.067-17.067h-289.61
                c-9.425,0-17.067,7.641-17.067,17.067v48.014H39.822c-3.137,0-5.689-2.552-5.689-5.689V173.731c0-3.137,2.552-5.689,5.689-5.689
                c13.094,0,419.57,0,432.356,0c3.137,0,5.689,2.552,5.689,5.689V345.433z"/>
        </g>
        <g>
            <path style={{ fill: props.strokeColor }} d="M400.808,199.988h-43.443c-9.425,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067h43.443
                c9.425,0,17.067-7.641,17.067-17.067S410.234,199.988,400.808,199.988z"/>
        </g>
        <g>
            <path style={{ fill: props.strokeColor }} d="M329.956,399.834H182.044c-9.425,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067h147.911
                c9.425,0,17.067-7.641,17.067-17.067S339.381,399.834,329.956,399.834z"/>
        </g>
        <g>
            <path style={{ fill: props.strokeColor }} d="M329.956,346.006H182.044c-9.425,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067h147.911
                c9.425,0,17.067-7.641,17.067-17.067S339.381,346.006,329.956,346.006z"/>
        </g>
        </svg>
    )
}

export default IconPrint