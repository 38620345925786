import React from 'react';
import styled from "styled-components";

const CreditCardPaymentMex = ({setModalOpen}) => {
    const submit = (e) => {
        e.preventDefault()
        setModalOpen(true)
    }
    return (
        <CreditCardForm onSubmit={submit}>
            <GroupInput>
                <ItemLabel>Número de tarjeta</ItemLabel>
                <ItemInput/>
            </GroupInput>

            <WrapperDate>
                <GroupDate>
                    <ItemLabel>Fecha de expiración</ItemLabel>
                    <WrapperInputs>
                        <ItemInput/>
                        <Separator>/</Separator>
                        <ItemInput/>
                    </WrapperInputs>
                </GroupDate>
                <GroupInput>
                    <ItemLabel>CVV code</ItemLabel>
                    <ItemInput/>
                </GroupInput>
            </WrapperDate>
            <Submit type="submit">Pagar</Submit>
        </CreditCardForm>
    )
};

export default CreditCardPaymentMex;

const CreditCardForm = styled.form`
  width: 100%;
  padding: 0 1rem;
`

const GroupInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
`

const ItemLabel = styled.label`
  color: #5B5B5B;
  margin-bottom: .5rem;
`

const WrapperDate = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  
`

const GroupDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

const WrapperInputs = styled.div`
  display: flex;
  align-items: center;
`

const Separator = styled.span`
  margin: 0 1rem;
`

const ItemInput = styled.input`
  display: block;
  width: 100%;
  padding: .7rem 1rem;
  background: #FDFDFD;
  border: 1px solid #CDCDCD;
  border-radius: 3px;
`

const Submit = styled.button`
  background-color: #EC2286 !important;
  color: #FFFFFF;
  width: 100%;
  padding: .8rem;
  margin-top: 3rem;
  border-radius: 3px;
`
