import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Controller } from 'react-hook-form'
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask'
import MaskedInput from 'react-input-mask'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FaChevronRight } from 'react-icons/fa'
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils'

import GreenRadio from '../atoms/GreenRadio'
import {extendedFieldBankList} from "../../utils/const";

const requiredRule = { value: true, message: 'Campo Obrigatório' }
const cpfCnpjRule = [{
  value: /^.{14,18}$/,
  message: 'CPF/CNPJ inválido'
}, {
  valid: v => (isValidCPF(v) || isValidCNPJ(v)) || 'CPF/CNPJ inválido'
}]
const onlyText = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
const emailRule = {
  value: /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_\-.]+\.([a-zA-Z0-9_\-.]+)$/,
  message: 'Email inválido'
}
const nameRule = {
  value: onlyText,
  message: 'Preencher com nome'
}

const lastNameRule = {
  value: onlyText,
  message: 'Preencher com Sobrenome'
}

const phoneRule = {
  value: /^.{15,16}$/,
  message: 'Celular inválido'
}

const branchRule = (selectedBank) => ({
    value: selectedBank === 'DOBRASIL' ? /^.{6}$/ : /^.{4}$/,
    message: 'Agência inválida'
})

const accountRule = {
  value: /^.{7}$/,
  message: 'Número inválido'
}

const ErrorMessage = ({ errors, field }) => {
  return (
    <ErrorMessageText
      message={errors?.[field]?.message}
    >
      {errors?.[field]?.message}
    </ErrorMessageText>
  )
}

const TransferForm = ({
  register,
  handleSubmit,
  errors,
  isValid,
  control,
  setValue,
  onSubmit,
  onSubmitMobile,
  selectedBank,
  payerData,
  data = {},
  showSubmit = true
}) => {
  useEffect(() => {
    const {FullName, DocType, ...shopper} = data
    Object.keys(shopper).map(key => {
      setValue(key, data[key], { shouldDirty: true, shouldValidate: true })
      return true
    })
  }, [data, setValue])

  return (
    <Form>
      <ItemContainer>
        <ItemLabel>CPF/CNPJ</ItemLabel>
        <Controller
          name="DocNumber"
          rules={{ required: requiredRule, pattern: Boolean(payerData) ? undefined : cpfCnpjRule[0], validate: Boolean(payerData) ? undefined : cpfCnpjRule[1] }}
          control={control}
          render={({ field }) => {
            const { onChange, value } = field
            return (
              <ItemInput
                as={CpfCnpj}
                disabled={Boolean(payerData)}
                value={value}
                onChange={onChange}
              />
            )
          }}
        />
        <ErrorMessage errors={errors} field="DocNumber" />
      </ItemContainer>
      <ItemContainer>
        <ItemLabel>E-mail</ItemLabel>
        <ItemInput
          type="email"
          disabled={Boolean(payerData)}
          {...register('Email', { required: requiredRule, pattern: emailRule })}
        />
        <ErrorMessage errors={errors} field="Email" />
      </ItemContainer>
      <ItemContainer>
        <ItemLabel>Nome</ItemLabel>
        <ItemInput
          type="text"
          disabled={Boolean(payerData)}
          {...register('FirstName', { required: requiredRule, pattern: nameRule })}
        />
        <ErrorMessage errors={errors} field="FirstName" />
      </ItemContainer>
      <ItemContainer>
        <ItemLabel>Sobrenome</ItemLabel>
        <ItemInput
            type="text"
            disabled={Boolean(payerData)}
            {...register('LastName', { required: requiredRule, pattern: lastNameRule })}
        />
        <ErrorMessage errors={errors} field="LastName" />
      </ItemContainer>
      <ItemContainer className="flex flex-col max-w-14">
        <ItemLabel className="mb-5 text-wp-gray-5 text-sm">Celular</ItemLabel>
        <ItemInput
          as={MaskedInput}
          mask="(99) 9 9999-9999"
          type="text"
          maskChar={null}
          disabled={Boolean(payerData)}
          className="disabled:bg-wp-gray-6 disabled:border-wp-gray-6 border-wp-gray-7 border rounded py-2.5 px-5 text-sm text-wp-gray-5"
          {...register('Mobile', { required: requiredRule, pattern: Boolean(payerData) ? undefined : phoneRule })}
        />
        <ErrorMessage errors={errors} field="Mobile" />
      </ItemContainer>
      {selectedBank === 'ITAÚ' && <ItemFullContainer>
        <ItemLabel>Tipo de Conta:</ItemLabel>
        <Controller
          name="accountType"
          rules={{ required: requiredRule }}
          control={control}
          defaultValue="checking"
          render={({ field }) => {
            const { onBlur, onChange, value } = field;
            return (
              <RadioGroup
                row
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              >
                <FormControlLabel
                  control={<GreenRadio size="small" />}
                  label="Corrente"
                  value="checking"
                />
                <FormControlLabel
                  control={<GreenRadio size="small" />}
                  label="Poupança"
                  value="saving"
                />
                <FormControlLabel
                  control={<GreenRadio size="small" />}
                  label="Jurídica"
                  value="business"
                />
              </RadioGroup>
            )
          }}
        />
      </ItemFullContainer>}
      {extendedFieldBankList.includes(selectedBank) && <ItemFullWrapper>
        <ItemFullContainer>
          {selectedBank === 'DOBRASIL' && <span>Dados bancários</span> }
        </ItemFullContainer>
        <ItemContainer>
          <ItemLabel>Agência</ItemLabel>
          <ItemInput
            as={MaskedInput}
            mask={selectedBank === 'DOBRASIL' ? '9999-9' : '9999'}
            maskChar={null}
            type="text"
            className="disabled:bg-wp-gray-6 disabled:border-wp-gray-6 border-wp-gray-7 border rounded py-2.5 px-5 text-sm text-wp-gray-5"
            {...register('branch', { required: requiredRule, pattern: branchRule(selectedBank) })}
          />
          <ErrorMessage errors={errors} field="branch" />
        </ItemContainer>
        <ItemContainer>
          <ItemLabel>Número da Conta com Dígito</ItemLabel>
          <ItemInput
            type="text"
            as={MaskedInput}
            mask="99999-9"
            maskChar={null}
            className="disabled:bg-wp-gray-6 disabled:border-wp-gray-6 border-wp-gray-7 border rounded py-2.5 px-5 text-sm text-wp-gray-5"
            {...register('account', { required: requiredRule, pattern: accountRule })}
          />
          <ErrorMessage errors={errors} field="account" />
        </ItemContainer>
      </ItemFullWrapper>}
      {showSubmit && <SubmitContainer>
        <Submit
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          <SubmitText>Detalhes do pedido e instruções de pagamento</SubmitText>
          <FaChevronRight size="16px" style={{ placeSelf: 'center' }} />
        </Submit>
        <SubmitMobile
          type="submit"
          onClick={handleSubmit(onSubmitMobile)}
          disabled={!isValid}
        >
          <SubmitText>Detalhes do pedido e instruções de pagamento</SubmitText>
          <FaChevronRight size="16px" style={{ placeSelf: 'center' }} />
        </SubmitMobile>
      </SubmitContainer>}
    </Form>
  )
}

export default TransferForm

const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  place-content: space-between;
  column-gap: 5rem;
  row-gap: 1.5rem;
`
const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen
  and (max-width : 700px) {
    grid-column-start: span 2;
  }
`
const ItemFullContainer = styled.div`
  grid-column: span 2 / span 2;
  display: flex;
  width: 100%;
  flex-direction: column;
`
const ItemFullWrapper = styled.div`
  grid-column: span 2 / span 2;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  place-content: space-between;
  column-gap: 5rem;
  row-gap: 1.5rem;
`
const ItemLabel = styled.span`
  color: ${({theme}) => theme.colors['wp-gray-5']};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
`
const ItemInput = styled.input`
  color: ${({ theme }) => theme.colors['wp-gray-5']};
  ${({disabled, theme}) => disabled ? `background-color: ${theme.colors['wp-gray-6']};` : ''}
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 1.25rem;
  border-color: ${({disabled, theme}) => disabled ? theme.colors['wp-gray-6'] : theme.colors['wp-gray-7']};
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
`
const SubmitContainer = styled.div`
  grid-column: span 2 / span 2;
  margin-top: 1.75rem;
`
const Submit = styled.button`
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'} !important;
  background-color: ${({ disabled, theme }) => disabled ? theme.colors['wp-gray-8'] : theme.colors['wp-blue']} !important;
  color: ${({theme}) => theme.colors['wp-white']} !important;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  place-items: center;
  border-radius: 0.25rem;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1rem;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`
const SubmitMobile = styled.button`
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'} !important;
  background-color: ${({ disabled, theme }) => disabled ? theme.colors['wp-gray-8'] : theme.colors['wp-blue']} !important;
  color: ${({theme}) => theme.colors['wp-white']} !important;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  place-items: center;
  border-radius: 0.25rem;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1rem;
  @media only screen
  and (min-width : 700px) {
    display: none;
  }
  @media only screen
  and (max-width : 700px) {
    font-size: 0.75rem;
  }
`
const SubmitText = styled.span`
  grid-column: span 5 / span 5;
  place-self: end;
`

const ErrorMessageText = styled.span`
  ${({message}) => !message ? 'display: none;' : ''}
  color: rgba(239, 68, 68, .8);
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.25rem;
`
