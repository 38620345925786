import React from 'react'

const IconSuccess = (props) => {

    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.729 36.375"  stroke={props.color} width={props.width} height={props.height} >
            <defs>
            </defs>
            <g transform="translate(1 1.238)">
                <ellipse cx="13.108" cy="13.108" style={{ fill: "none", stroke: props.strokeColor, strokeMiterLimit: "10" }} rx="13.108" ry="13.108" transform="translate(3.925 3.997)"/>
                <path d="M17.033 0A17.033 17.033 0 1 1 0 17.033 17.033 17.033 0 0 1 17.033 0z" style={{ fill: "none", stroke: props.strokeColor, strokeWidth: "2px" }} transform="translate(0 .072)"/>
                <path d="M1142.342 171.945l6.844 5.227 20.913-20.913" style={{ fill: "none", stroke: props.strokeColor, strokeWidth: "3.5px" }} transform="translate(-1133.608 -156.259)"/>
            </g>
        </svg>
    )
}

export default IconSuccess