import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './scss/main.scss'

import store from './redux/store'
import { Provider } from 'react-redux'

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>
    , document.getElementById('wepay4u')
)
serviceWorker.unregister()
