import React from 'react'
import styled from 'styled-components'

const CardMobileComponent = ({ title, text, subtext, last }) => {
  return (
    <Container last={last}>
      <div
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        <Title>{title}</Title>
      </div>
      <TextContainer>
        <Text>{text}</Text>
        <Subtext>{subtext}</Subtext>
      </TextContainer>
    </Container>
  )
}

export default CardMobileComponent

const Container = styled.div`
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  padding: 0.625rem 1.875rem;
  place-items: end;
  align-items: flex-start;
  border-style: solid;
  border-color: ${({theme}) => theme.colors['wp-blue']};
  border-radius: 4px;
  border-width: 1px;
  ${({last}) => !last ? 'margin-bottom: .875rem;' : ''}
  margin-left: 0.75rem;
  margin-right: 0.75rem;
`

const Title = styled.span`
  color: ${({theme}) => theme.colors['wp-gray-2']};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Text = styled.span`
  color: ${({theme}) => theme.colors['wp-blue']};
  font-size: 1rem;
`
const Subtext = styled.span`
  color: ${({theme}) => theme.colors['wp-blue']};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  align-self: flex-end;
`