import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'

const GreenRadio = withStyles({
  root: {
    color: '#E1E1E1',
    '&$checked': {
      color: '#00BB31',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default GreenRadio