import React from 'react';
import Modal from 'react-modal';

const ModalResponse = ({ modalOpen, setModalOpen, children }) => (
    <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={{
            overlay: {
                backgroundColor: 'rgba(132, 132, 132, .4)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            content: {
                width: 500,
                minHeight: 100,
                position: 'relative',
                borderRadius: 12,
                marginTop: -140,
                inset: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'

            },
        }}
        contentLabel="Example Modal"
    >
        {children}
    </Modal>
);

export default ModalResponse;
