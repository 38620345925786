import React, { useState } from 'react'
import styled from 'styled-components'
import Barcode from 'react-barcode'
import { AiOutlineClockCircle } from 'react-icons/ai';

import CardComponent from '../atoms/Card'
import { BiPrinter } from 'react-icons/bi'
import dayjs from 'dayjs'
import CardMobileComponent from '../atoms/CardMobile'
import ModalBottom from '../atoms/ModalBottom'

const BankBilletView = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Container>
      <CardsContainer>
        <CardContainer>
          <CardComponent
            title="Número do Pedido:"
            text={data.data.dataOperation.PaymentCode}
          />
        </CardContainer>
        <CardContainer>
          <CardComponent
            title="Valor Total:"
            text={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(data.data.dataOperation.Amount))}
          />
        </CardContainer>
        <CardContainer>
          <CardComponent
            title="Pedido válido até:"
            text={dayjs(data.data.dataOperation.TxExpiration).format('DD/MM/YYYY')}
            subtext={dayjs(data.data.dataOperation.TxExpiration).format('HH:mm:ss') + ' hrs'}
          />
        </CardContainer>
      </CardsContainer>
      <ModalBottom
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      >
        <CardMobileComponent
          title="N. do pedido:"
          text={data.data.dataOperation.PaymentCode.slice(0, 10)}
        />
        <CardMobileComponent
          title="Valor Total:"
          text={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(data.data.dataOperation.Amount))}
        />
        <CardMobileComponent
          last={true}
          title="Válido até:"
          text={dayjs(data.data.dataOperation.TxExpiration).format('DD/MM/YYYY')}
          subtext={dayjs(data.data.dataOperation.TxExpiration).format('HH:mm:ss') + ' hrs'}
        />
      </ModalBottom>
      <InfoContainer>
        <InfoTitleMobile>Instruções para pagamento</InfoTitleMobile>
        <InstructionsText>
          Para pagar pelo Internet Banking, copie a linha digitavel ou
          escaneie o código de barras. Para pagar em qualquer banco,
          caixa eletrónico  ou lotérica, por favor, imprima o boleto.
        </InstructionsText>
        <PixInfoContainer>
          <BarcodeContainer>
            <Barcode value={data.data.channel.BankBillet.code} displayValue={false} width={1} />
          </BarcodeContainer>
          <WarningContainer>
            <AiOutlineClockCircle size={20} style={{ alignSelf: 'flex-start' }} />
            <WarningText>Lembre-se que para pagamento via boleto existe um
              tempo de compensação de até 3 dias úteis.</WarningText>
          </WarningContainer>
        </PixInfoContainer>
        <InfoTitle>Ou copie este código para fazer o pagamento</InfoTitle>
        <CodeContainerUp>
          <CodeContainer>
            <Code>{data.data.channel.BankBillet.code.substr(0, 51)}...</Code>
            <Copy>Copiar</Copy>
          </CodeContainer>
          <Print><BiPrinter size={16} /></Print>
        </CodeContainerUp>
      </InfoContainer>
      <CopyButtonMobileContainer>
        <CopyButtonMobile onClick={() => {navigator.clipboard.writeText(data.data.channel.Pix.code)}}>Copiar código do boleto</CopyButtonMobile>
      </CopyButtonMobileContainer>
      <InfoMobileButtonContainer>
        <InfoMobileButton onClick={() => setModalOpen(true)}>Dados da compra</InfoMobileButton>
      </InfoMobileButtonContainer>
    </Container>
  )
}

export default BankBilletView

const Container = styled.div`
  align-self: center;
  max-width: 846px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
`

const CardsContainer = styled.div`
  padding-bottom: 2rem;
  padding-right: 3rem;
  padding-top: 2.25rem;
  border-style: solid;
  border-width: 0;
  border-right-width: 1px;
  border-color: rgba(229, 231, 235, 1);
  max-width: 15rem;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`
const CardContainer = styled.div`
  margin-bottom: 1rem;
`

const InfoContainer = styled.div`
  margin-left: 3.125rem;
  margin-top: 1rem;
  grid-column: span 2 / span 2;
  @media only screen
  and (max-width : 700px) {
    grid-column-start: span 3;
  }
`
const InfoTitle = styled.span`
  color: ${({theme}) => theme.colors['wp-blue']};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`
const InfoTitleMobile = styled.span`
  color: ${({theme}) => theme.colors['wp-blue']};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
`

const InstructionsText = styled.p`
  color: ${({theme}) => theme.colors['wp-gray-1']};
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-right: 2.5rem;
`

const PixInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 2.25rem;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`
const BarcodeContainer = styled.div`
  width: 358px;
  margin-bottom: 1.25rem;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
`
const WarningContainer = styled.div`
  color: ${({theme}) => theme.colors['wp-gray-11']};
  padding: 0.75rem;
  margin-bottom: 3.125rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const WarningText = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
  margin-left: 0.625rem;
`
const CodeContainerUp = styled.div`
  display: flex;
  margin-top: 1.375rem;
  @media only screen
  and (max-width : 700px) {
    margin-top: 4.375rem;
  }
`
const CodeContainer = styled.div`
  padding: 0.5rem 1.25rem;
  background-color: ${({theme}) => theme.colors['wp-gray-0']};
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 27.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media only screen
  and (max-width : 700px) {
    margin-right: 4rem;
  }
`
const Code = styled.div`
  grid-column: span 5 / span 5;
  @media only screen
  and (max-width : 700px) {
    grid-column: span 6 / span 6;
  }
  color: ${({theme}) => theme.colors['wp-gray-1']};
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 0.125rem;
  justify-content: center;
  flex-direction: column;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Copy = styled.div`
  color: ${({theme}) => theme.colors['wp-blue-1']};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  cursor: pointer;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`

const Print = styled.div`
  align-self: center;
  margin-left: 1.25rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors['wp-blue']};
  border-color: ${({ theme }) => theme.colors['wp-blue']};
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  display: flex;
  justify-content: center;
  width: 28px;
  height: 28px;
  align-items: center;
  @media only screen
  and (max-width : 700px) {
    display: none;
  }
`
const CopyButtonMobileContainer = styled.div`
  display: none;
  @media only screen
  and (max-width : 700px) {
    display: flex;
  }
  width: 100%;
  justify-content: center;
  grid-column-start: span 3;
  margin-top: 2.25rem;
  margin-bottom: 1.5rem;
`
const CopyButtonMobile = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors['wp-blue']};
  padding: 0.625rem 1.1rem;
  color: ${({ theme }) => theme.colors['wp-white']};
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
`
const InfoMobileButtonContainer = styled.div`
  display: none;
  @media only screen
  and (max-width : 700px) {
    display: flex;
  }
  justify-content: center;
  grid-column-start: span 3;
  padding-top: 1.5rem;
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
  margin-left: 2.125rem;
  margin-right: 2.125rem;
  border-color: rgba(229, 231, 235, 1);
`
const InfoMobileButton = styled.div`
  padding: 0.625rem 2rem;
  @media only screen
  and (max-width : 700px) {
    padding: 0.625rem 5.375rem;
  }
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors['wp-blue']};
  color: ${({ theme }) => theme.colors['wp-blue']};
  cursor: pointer;
`